import React, { Component } from "react";

export default class Video extends Component {
  render() {
    return (
      <section className="video">

        <div className="video-play">
          <div className="close-video">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="container">
          <div className="video-caption">How we work</div>

          <div className="video-wrap col-12">
            <div className="play">
              <div className="main-play-button"><i className="fas fa-play"></i></div>
            </div>
            <div className="main-video" id="play-main-video" data-video="AWh9gESAOO0"></div>
          </div>
        </div>
      </section>
    )
  }
}
