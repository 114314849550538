import React, { Component } from 'react';

class Navigation extends Component {
  render() {
    return (
      <nav className="navigation">
        <ul>
          <li><a onClick={this.props.handleClickMenuItem} href="#">Home</a></li>
          <li><a onClick={this.props.handleClickMenuItem} href="#about">About Us</a></li>
          <li><a onClick={this.props.handleClickMenuItem} href="#services">Services</a></li>
          <li><a onClick={this.props.handleClickMenuItem} href="#talent">Talent</a></li>
          <li><a onClick={this.props.handleClickMenuItem} href="#contact">Contact</a></li>
        </ul>
        {this.props.children}
      </nav>
    );
  }
}

export default Navigation;
