import React, { Component } from "react";
import {EMAIL_ADDRESS} from "../constants";

export default class Services extends Component {
  render() {
    return (
      <section className="services" id='services'>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-last order-lg-first services-icons">
              <img src="img/mhuncho.jpg" alt="#" className="img-responsive"/>

            </div>

            <div className="col-lg-5 col-12 offset-lg-1 no-pd-left">
              <div className="upper-caption services-upper-caption">OUR SERVICES</div>
              <h2 className="caption services-caption">OUR SERVICES</h2>

              <div className="about-description services-description">
                <p>Merge Marketing is an integrated full service agency offering a range of bespoke services for brands and talent alike which include; <strong>brand strategy</strong>, <strong>talent management</strong> and <strong>media planning & buying</strong>. Find list of services here:</p>
                <ul className="services-list">
                  <li>Branding</li>
                  <li>Creative Strategy</li>
                  <li>Media Planning & Buying</li>
                  <li>Campaign Execution</li>
                  <li>Social Data Auditing</li>
                  <li>Talent Management</li>
                </ul>
                <p><a href={`mailto:${EMAIL_ADDRESS}`}> Get in touch</a> for a free consultation so we can devise a tailored approach to your marketing needs.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
