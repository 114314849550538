import React, { Component } from "react";

export default class Projects extends Component {
  render() {
    return (
      <section className="projects" id='talent'>
        <div className="container">
          <div className="upper-caption center">Some of the people we've worked with</div>
          <h2 className="caption center">Talent</h2>

          <div className="project-items">
            <div className="row masonry-container">
              <div className="project-item col-lg-4 col-sm-6">
                <a href="#">
                  <div className="project-hover">
                    M Huncho
                    <span>2018 - 2019</span>
                  </div>
                </a>
                <img src="img/mhuncho.png" alt="M Huncho"/>
              </div>

              <div className="project-item col-lg-4 col-sm-6">
                <a href="#">
                  <div className="project-hover">
                    Bernicia Boateng
                  </div>
                </a>
                <img src="img/bernicia-boateng.png" alt="Bernicia Boateng"/>
              </div>




            </div>
          </div>

        </div>
      </section>
    )
  }
}
