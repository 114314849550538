import React, { Component } from "react";
import Navigation from "./Navigation";
import {EMAIL_ADDRESS, FACEBOOK, INSTAGRAM, TWITTER} from "../constants";

export default class Menu extends Component {
  render() {
    return (
      <div className={`mobile-menu ${this.props.menuOpened ? 'mobile-menu--active' : ''}`}>
        <Navigation handleClickMenuItem={this.props.handleToggleMenu}>
          <ul className="mobile-menu__social">
            <li><a href={FACEBOOK}><i className="fab fa-2x fa-facebook-f"/></a></li>
            <li><a href={TWITTER}><i className="fab fa-2x fa-twitter"/></a></li>
            <li><a href={INSTAGRAM}><i className="fab fa-2x fa-instagram"/></a></li>
            <li><a href={`mailto:${EMAIL_ADDRESS}`}><i className="far fa-2x fa-envelope"/></a></li>
          </ul>
        </Navigation>
      </div>
      )
  }
}
