import React, { Component } from "react";

export default class Testimonials extends Component {
  render() {
    return (
      <section className="testimonials" id='te'>
        <div className="container">
          <h2 className="caption left">What people say about us<span>:</span></h2>
          <div className="row">
            <div className="owl-carousel col-12">
              <div className="item row">
                <div className="comment-img col-lg-6 col-md-4">
                  <img src="https://via.placeholder.com/540x371" alt="#"/>
                </div>

                <div className="comment-body col-lg-6 col-md-8">
                  <div className="comment-name">David Regan <div><i className="fas fa-quote-left"></i></div>
                  </div>
                  <div className="comment-text">
                    Gave read use way make spot how nor. In daughter
                    goodness an likewise oh consider at procured wandered.
                    Songs words wrong by me hills heard timed. Happy eat
                    may doors songs. Be ignorant so of suitable dissuade
                    weddings together. Least whole timed we is.
                  </div>
                  <div className="comment-date">14 August</div>
                </div>
              </div>

              <div className="item row">
                <div className="comment-img col-lg-6 col-md-4">
                  <img src="https://via.placeholder.com/540x371" alt="#"/>
                </div>

                <div className="comment-body col-lg-6 col-md-8">
                  <div className="comment-name">Michael Jordan <div><i className="fas fa-quote-left"></i></div>
                  </div>
                  <div className="comment-text">
                    Gave read use way make spot how nor. In daughter
                    goodness an likewise oh consider at procured wandered.
                    Songs words wrong by me hills heard timed. Happy eat
                    may doors songs. Be ignorant so of suitable dissuade
                    weddings together. Least whole timed we is.
                  </div>
                  <div className="comment-date">14 August</div>
                </div>
              </div>

              <div className="item row">
                <div className="comment-img col-lg-6 col-md-4">
                  <img src="https://via.placeholder.com/540x371" alt="#"/>
                </div>

                <div className="comment-body col-lg-6 col-md-8">
                  <div className="comment-name">Andrew Morrison <div><i className="fas fa-quote-left"></i></div>
                  </div>
                  <div className="comment-text">
                    Gave read use way make spot how nor. In daughter
                    goodness an likewise oh consider at procured wandered.
                    Songs words wrong by me hills heard timed. Happy eat
                    may doors songs. Be ignorant so of suitable dissuade
                    weddings together. Least whole timed we is.
                  </div>
                  <div className="comment-date">14 August</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
