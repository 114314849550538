import React, { Component } from "react";

export default class GetInTouch extends Component {
  render() {
    return (
      <section className="get-in-touch" id='contact'>
        <div className="container">
          <div className="row">
            <div className="get-in-touch-caption">Let's work together</div>
            <p className="get-in-touch-caption-undercaption">
              Together we can create something really cool
              and impressive
            </p>
          </div>
        </div>
      </section>
    )
  }
}
