import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (<section className="about" id='about'>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">

            <div className="upper-caption">ABOUT Merge Marketing</div>
            <h2 className="caption">Where <span>Sports</span> x <span>Fashion</span> Collide</h2>

            <div className="about-description">
              A youth culture led talent marketing agency passionate about connecting brands with the finest of cultural tastemakers, movers and shakers within the worlds of <strong>Music</strong>, <strong>Sports</strong>, <strong>Entertainment</strong> and <strong>Lifestyle</strong>.
            </div>

          </div>

          <div className="about-images col-md-6 col-12">
            <div className="image-1">
              <img src="img/aj.png" alt="AJ"/>
            </div>
          </div>

        </div>
      </div>
    </section>)
  }
}
