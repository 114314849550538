import React, { Component } from 'react';
import Navigation from "./Navigation";
import Menu from "./Menu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false
    }
  }
  toggleMenu = () => {
    this.setState({
      menuOpened: !this.state.menuOpened
    })
  };
  render() {
    return (
      <header className="header">
        <div className="top-line">
          <div className="container">
            <div className="row">
              <button className="hamburger" onClick={this.toggleMenu}>
                <span className="hamburger-box"><span className="hamburger-inner"/></span>
              </button>
              <a href='#' className="logo">
                <img src="img/logo.png" alt="#"/>
              </a>

              <div className="main-menu">
                <Navigation/>
              </div>
            </div>
          </div>
        </div>

        <div className="caption-wrap">
          <div className="container">
            <div className="row">
              <h1 className="main-caption col-12 col-lg-9">Where Culture meets Brands</h1>
              <div className="lower-caption col-12">A Talent Marketing Agency</div>
              <div className="header-button col-12">
                <a href="#contact">Get In Touch</a>
              </div>
            </div>
          </div>
        </div>
        <Menu menuOpened={this.state.menuOpened} handleToggleMenu={this.toggleMenu}/>
      </header>
    );
  }
}

export default Header;
