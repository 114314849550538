import React, { Component } from 'react';
import './scss/main.scss';
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import GetInTouch from "./components/GetInTouch";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Testimonials from "./components/Testimonials";
import Video from "./components/Video";
import Clients from "./components/Clients";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header/>
        <About/>

        <Services/>

        {/*<Video/>*/}

        {/*<Projects/>*/}
        <Clients/>

        <GetInTouch/>
        <Footer/>
      </div>
    );
  }
}

export default App;
