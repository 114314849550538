import React, { Component } from "react";

export default class Clients extends Component {
  render() {
    return (
      <section className="projects text-center" id='pr'>
        <div className="container">
          <div className="upper-caption center">some of the brands we've worked with</div>

          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <a href="https://eu.puma.com/uk">
                <img src="img/puma.png" alt="puma logo"/>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a href="https://www.mixtapemadness.com" target="_blank">
                <img src="img/mixtape-madness.png" alt="Mixtape Madness logo"/>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a href="https://tidal.com/">
                <img src="img/tidal.png" alt="tidal logo"/>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a href="https://tidal.com/">
                <img src="img/barclaycard.png" alt="barclaycard logo"/>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a href="https://tidal.com/">
                <img src="img/global-radio.png" alt="Global Radio logo"/>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a href="https://tidal.com/">
                <img src="img/spotify.png" alt="Spotify logo"/>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
