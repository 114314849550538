import React, { Component } from 'react';
import {FACEBOOK, INSTAGRAM, TWITTER, EMAIL_ADDRESS} from "../constants";

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-center">
        <div className="container">
          <div className="row">

            <div className="col-md-12 footer-contacts">
              <div className="footer-logo">
                <img width="300" src="img/logo.png" alt="Merge Marketing Logo" />
              </div>
              <ul className="footer-contacts-list">
                <li><a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a></li>
              </ul>
              <ul className="footer-social">
                <li><a href={FACEBOOK}><i className="fab fa-2x fa-facebook-f"/></a></li>
                <li><a href={TWITTER}><i className="fab fa-2x fa-twitter"/></a></li>
                <li><a href={INSTAGRAM}><i className="fab fa-2x fa-instagram"/></a></li>
                <li><a href={`mailto:${EMAIL_ADDRESS}`}><i className="far fa-2x fa-envelope"/></a></li>
              </ul>
            </div>

            <div className="col-12 rights">© 2018 All Rights Reserved.</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
